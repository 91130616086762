@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Sofia+Sans:wght@300;400;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  object-fit: contain;
}

button {
  cursor: pointer;
  border: none;
}

.none-media {
  opacity: 0;
  height: 1px;
  width: 1px;
  position: absolute;
  z-index: -99999;
  bottom: 0;
  display: none;
}

body {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper {
  overflow: scroll;
  width: 100%;
  height: 100%;
}

.main-wrapper {
  position: relative;
  /* background-image: url("../public/images/bg.png");
  width: calc(100vh - 141px); */
}

.blushed-bg {
  width: 100%;
  display: block;
}

.policy-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 50px; */
  background: black;
  height: 150px;
  padding: 20px 40px;
}

.line {
  margin-right: 40px;
  width: 60%;
  height: 2px;
  background: #ff60ac;
}

.policy-item {
  margin: 0 10px;
  color: white;
  font-family: Sofia Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.button-top {
  max-width: 200px;
  max-height: 50px;
  position: absolute;
  top: 15px;
  right: 40px;
}

.button-top:hover {
  cursor: pointer;
}

.hover:hover {
  cursor: pointer;
}

.button {
  width: 100%;
  height: 100%;
}
.download-block {
  position: absolute;
  bottom: 110px;
  left: 150px;
}

.button-download {
  margin: 10px;
  max-width: 200px;
  max-height: 50px;
}

@media (min-width: 1280px) {
  .button-top {
    top: 30px;
    right: 50px;
  }
}

@media (max-width: 950px) {
  .button-top {
    max-width: 150px;
    max-height: 35px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  height: 70px;
  background: black;
}
.blushed-logo {
  width: auto;
  height: 44px;
  display: block;
}

.button-get-app {
  width: auto;
  height: 44px;
}

.link-wrapper {
  display: flex;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}
